import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles1.json";
import { withRouter } from "react-router-dom";
import { AlertContext } from "../../contexts";
import {
  Container,
  Div,
  H6,
  Divider,
  Upload,
  TextInput,
  TapSelect,
  Text,
  // DateTimepicker,
  MobileNumberWithCode,
  Button,
  TermsAndConditions,
  Popover,
  Icons,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  ValidateEmail,
  getMobileNumber,
  getUserInfo,
  checkName,
} from "../../utils";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment";
import { withStyles } from "@material-ui/core";
import { DobAge } from "../";
import { UIColor, mobileNumberValidation } from "../../utils";

const styles = (theme) => ({
  upload: {
    "& .uploadWrapper": {
      boxShadow: "none",
      "& p": {
        lineHeight: 1,
        fontFamily: "pc_semibold",
        fontSize: 12,
        color: UIColor.tertiaryText.color,
      },
    },
  },
  textField: {
    marginRight: "15px",
    "& input": {
      color: UIColor.primaryText.color,
      fontSize: "14px",
      border: "1px solid #DEE5EC",
      fontFamily: "pc_medium",
      borderRadius: 8,
    },
    "& input::placeholder": {
      fontFamily: "pc_regular",
      color: UIColor.tertiaryText.color,
    },
  },
  tapSelect: {
    "& .selectionDiv": {
      height: 40,
      borderRadius: 8,
      minWidth: 48,
      margin: "8px 8px 0px 0px",
      "&:last-of-type": {
        marginRight: 0,
      },
      "& span": {
        fontFamily: "pc_regular",
        fontSize: 14,
      },
    },
    "& .active": {
      "& span": {
        fontFamily: "pc_medium !important",
      },
    },
  },
  mobile: {
    "& .callingCodes,input": {
      fontSize: 14,
      color: UIColor.primaryText.color,
      fontFamily: "pc_medium",
      letterSpacing: "-0.34px",
      fontWeight: "normal",
    },
    "& input::placeholder": {
      fontFamily: "pc_medium",
    },
    // '& #addmember_form_mobilenumberwithcode':{
    //   borderColor: UIColor.lineBorderFill.color
    // },
    "& .mobileCountryDivider": {
      borderColor: UIColor.lineBorderFill.color,
    },
  },
  termsAndConditions: {
    "& .termsConditionsCheckbox": {
      borderRadius: 2,
    },
    "& .termsConditionsLabel": {
      flex: 1,
    },
  },
  a_c_c_aed_fbeabbf: {
    fontSize: "20px",
    color: UIColor.primaryText.color,
    letterSpacing: 0,
    lineHeight: 1,
    margin: 0,
  },
  b__ae_ba_fcfdfc: {
    color: UIColor.primaryColor.color,
    fontSize: "14px",
    letterSpacing: 0,
    margin: 0,
    cursor: "pointer",
  },
});

const Styles = {
  inputStyle: {
    borderRadius: 8,
    color: UIColor.primaryText.color,
    border: "1px solid #DEE5EC",
  },
  errorStyle: {
    borderRadius: 8,
    color: UIColor.primaryText.color,
  },
};

class AppointmentDrawerSigningPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      middleName: "",
      gender: "",
      image: {},
      genderOptions: [],
      dob: {
        val: null,
        milli: null,
        value: "",
      },
      relation: "",
      emailId: "",
      mobileNo: {},
      opencalendar: false,
      loadingReadPerson: false,
      emailIdChecked: false,
      // dropdownData: [
      //   {
      //     name: "India",
      //     flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      //     altSpellings: "IND",
      //     callingCodes: "91",
      //   },
      // ],
    };
    this.getUserInfo = getUserInfo.bind(this);
    this.mobileRef = React.createRef();
  }

  getCountryCodelist = async () => {
    let code = await this.props.GET_COUNTRY_CODE_LIST();
    // this.setState({
    //   ...this.state,
    //   dropdownData: code?.payload?.data
    // })

    // let value = {
    //   countryData: code?.payload?.data?.filter(val => val?.callingCodes === '91')?.[0],
    //   mobileNo: "",
    // };

    // this.setState({
    //   ...this.state,
    //   mobileNo: value,
    // })
  };

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount = async () => {
    if (this.props.signUpData) {
      const data = this.props.signUpData;
      this.setState({
        mobileNo: data?.mobileNo,
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        gender: data?.gender,
        dob: {
          val: this.props.signUpData.dob,
          milli: moment().valueOf(),
        },
        emailId: data?.emailId,
        emailIdChecked: data?.dontAddEmail ? data?.dontAddEmail : false,
        //emailIdChecked: data?.dontAddEmail ? data?.dontAddEmail : data?.emailIdChecked ? data?.emailIdChecked : false,
        image: data?.image,
        relation: data?.relation,
      });
    }

    await this.getCountryCodelist();
    await this.props.RELATIONSHIP_MASTERS();

    // let value = {
    //   countryData: this.state.dropdownData[0],
    //   mobileNo: "",
    // };
    // if (this.state.dropdownData.length === 1) {
    //   this.setState({
    //     mobileNo: value,
    //   });
    // }
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  handleSignUp = async () => {
    const { addNewMember } = this.props;
    if (addNewMember) {
      if (!this.state.relation?.value) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Choose Relationship",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      if (this.state.mobileNo?.mobileNo?.length === 0) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Mobile Number is Mandatory",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
    }
    console.clear();
    console.log(this.state?.mobileNo);
    if (
      // this.state?.mobileNo?.mobileNo?.length > 9 &&
      // this.state?.mobileNo?.countryData?.callingCodes &&
      this.state?.firstName &&
      this.state?.gender !== "" &&
      // this.state?.lastName &&
      this.state.emailIdChecked
        ? ValidateEmail(this.state.emailId)
        : true &&
          (this.state.emailId ? ValidateEmail(this.state?.emailId) : true) &&
          this.state?.dob.val &&
          (this.checkAge(this.state?.dob.val) === "" ? true : false)
    ) {
      if (this.state?.gender === "" || this.state?.gender === undefined) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select the Gender",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }

      let res__ = mobileNumberValidation(
        this.state?.mobileNo?.mobileNo,
        this.state?.mobileNo?.countryData?.callingCodes
      );

      if (res__ === "Invalid Country code") {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: res__,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        this.setState({
          ...this.state,
          error: {
            callingCodes: true,
          },
        });
        return;
      } else if (res__ === false) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter valid mobile number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        this.setState({
          ...this.state,
          error: {
            mobileNo: true,
          },
        });
        return;
      }
      const {
        firstName,
        lastName,
        middleName,
        dob,
        gender,
        emailId,
        mobileNo,
        emailIdChecked,
      } = this.state;
      if (!this.props.addNewMember) {
        const phoneNumber = getMobileNumber(this.state.mobileNo);
        const personInfo = await this.props.READ_PERSON({ phone: phoneNumber });
        const isPerson =
          personInfo?.payload?.data?.id && personInfo?.payload?.data?.id !== 0;
        if (isPerson) {
          this.setState({
            error: "User Already Exists. Try Sign Up as Existing Member.",
          });
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "User Already Exists. Try Sign Up as Existing Member.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        }
        await this.props.REGISTER_USERS({
          mobileNo: `+${
            this.state.mobileNo?.countryData?.callingCodes ?? "00"
          }${this.state.mobileNo?.mobileNo}`,
          firstName,
          lastName,
          emailId: emailIdChecked ? emailId : "",
        });
        const status = checkError(this.props.registerUsers);
        if (status.isError) {
          this.setState({
            error: status.errorMessage,
          });
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: status.errMsg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        } else {
          const signUpData = {
            mobileNo,
            firstName,
            lastName,
            middleName,
            dob: dob.val,
            gender,
            emailId,
            image: this.state.image,
            dontAddEmail: emailIdChecked,
          };
          this.props.nextSection("addmemberotp", signUpData);
        }
      } else {
        const info = await this.getUserInfo();
        if (info.type === "guest") {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please Login to continue",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          return;
        }
        await this.props.REGISTER_USERS({
          mobileNo: getMobileNumber(mobileNo),
          firstName,
          lastName,
          emailId: emailIdChecked ? emailId : "",
        });
        const status = checkError(this.props.registerUsers);
        if (status.isError) {
          if (status.errMsg === "User already exists.") {
            this.setState({
              error: "User Already Exits please try using existing member.",
            });
            this.context.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "User Already Exits please try using existing member.",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          } else {
            this.setState({
              error: status.errMsg,
            });
            this.context.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: status.errMsg,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          }
        } else {
          const signUpData = {
            mobileNo,
            firstName,
            lastName,
            middleName,
            dob: dob.val,
            gender,
            emailId,
            addMember: true,
            image: this.state.image,
            otpNumber: getMobileNumber(mobileNo),
            memberInfo: {
              phone: getMobileNumber(this.state.mobileNo),
              dob: dob.val,
              firstName,
              lastName,
              gender,
              image: this.state.image,
              email: emailIdChecked ? this.state.emailId : "",
            },
            relation: this.state.relation,
            emailIdChecked: this.state.emailIdChecked,
          };
          this.props.nextSection("addmemberotp", signUpData);
        }
      }
    } else {
      this.setState({
        ...this.state,
        error: {
          mobileNo: mobileNumberValidation(
            this.state?.mobileNo?.mobileNo,
            this.state?.mobileNo?.countryData?.callingCodes
          ),
          callingCodes: this.state?.mobileNo?.countryData?.callingCodes
            ? false
            : true,
          firstName: this.state?.firstName?.length > 0 ? false : true,
          gender: this.state?.gender ? false : true,
          lastName: false,
          emailId:
            (this.state.emailIdChecked && !this.state.emailId) ||
            this.state?.emailId
              ? ValidateEmail(this.state?.emailId)
                ? false
                : true
              : false,
          dob:
            this.state?.dob?.val && this.checkAge(this.state?.dob?.val) === ""
              ? false
              : true,
        },
      });
      // this.context.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: 'Please fill all the mandatory fields',
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.right,
      //   tone: true
      // })
    }
  };
  handlepapover = (e) => {
    if (this.state.opencalendar) {
      this.handlepapoverclose();
    } else {
      this.setState({ opencalendar: e.currentTarget });
    }
  };
  handlepapoverclose = (val) => {
    if (val) {
      this.setState({ opencalendar: null });
    }
  };

  getErrorMessage = () => {
    let errMsg = "Please enter valid";
    let isFirst = true;
    let isSecond = true;
    if (this.state.error?.firstName) {
      isFirst = false;
      errMsg += " First Name";
    }
    if (this.state.error?.middleName) {
      isSecond = false;
      errMsg += `${!isFirst ? "," : ""} Middle Name`;
    }
    if (this.state.error?.lastName) {
      errMsg += `${!isSecond ? "," : ""} Last Name`;
    }
    return errMsg;
  };

  checkAge = (val) => {
    let years = moment().diff(moment(val, "DD-MM-YYYY"), "years");
    let days = moment().diff(moment(val, "DD-MM-YYYY"), "days");
    if (!(years < 200 && days >= 0)) {
      return "Enter Age should be less than 200 years";
    }
    return "";
  };

  render() {
    const { addNewMember, parent_id, classes, relationshipData } = this.props;
    return (
      <div id={`${parent_id}_addmember_form_parent_div`}>
        <Container
          id={`${parent_id}_addmember_form_parent_container`}
          key={"0"}
          name={"container"}
          fluid={true}
          // style={qdmstyles.Bc}
          style={{ padding: "16px 24px" }}
        >
          <Div
            id={`${parent_id}_addmember_form_title_parent_div`}
            key={"0"}
            className={"qdm"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "",
              width: "100%",
            }}
          >
            <Div
              key={"0"}
              id={`${parent_id}_addmember_form_title_sublevel_div`}
            >
              <H6
                id={`${parent_id}_addmember_form_title_h6`}
                key={"0"}
                name={addNewMember ? "Add Member" : "Sign Up as New Member"}
                style={{
                  fontSize: "20px",
                  color: UIColor.primaryText.color,
                  letterSpacing: 0,
                  lineHeight: 1,
                  margin: 0,
                }}
                className={"pc_semibold"}
              ></H6>
            </Div>

            <Div key={"1"} id={`${parent_id}_addmember_form_back_title_div`}>
              <H6
                className="pc_medium"
                onClick={() => this.props.nextSection("back")}
                style={{
                  color: UIColor.secondaryButtonColor.color,
                  fontSize: "14px",
                  letterSpacing: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
              >
                Back
              </H6>

              {/* <Button
                id={`${parent_id}_addmember_form_back_title_button`}
                key={"0"}
                name={"Back"}
                style={{
                  color: UIColor.primaryColor.color,
                  fontSize: "14px",
                  letterSpacing: 0,
                  margin: 0,
                  cursor: "pointer",
                }}
                onClick={() => this.props.nextSection("back")}
              ></Button> */}
            </Div>
          </Div>

          <Divider
            id={`${parent_id}_addmember_form_titile_bottom_divider`}
            style={{ margin: "16px 0px" }}
            key={"1"}
          ></Divider>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Div
              style={{
                background: UIColor.lineBorderFill.color,
                width: "64px",
                height: "64px",
                borderRadius: "50%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // overflow: "hidden",
              }}
              id={`${parent_id}_addmember_form_upload_image_parent_div`}
              className={classes.upload}
            >
              <Upload
                id={`${parent_id}_addmember_form_upload_image`}
                key={"2"}
                handleChange={(e, value) => {
                  if (
                    value.type === "image/png" ||
                    value.type === "image/jpg" ||
                    value.type === "image/jpeg"
                  ) {
                    if (value.size > 1000000) {
                      this.context.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please Select an Image with size less than 1MB.",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                        tone: true,
                      });
                    } else {
                      this.changeState("image", { url: e, file: value });
                    }
                  } else {
                    this.context.setSnack({
                      open: true,
                      severity: AlertProps.severity.error,
                      msg: "Please Select an Image file.",
                      vertical: AlertProps.vertical.top,
                      horizontal: AlertProps.horizontal.right,
                      tone: true,
                    });
                  }
                }}
                label={
                  this.state.image?.url ? (
                    <img
                      style={{
                        width: "64px",
                        height: "64px",
                        borderRadius: "50%",
                      }}
                      src={this.state.image?.url}
                      alt="Profile"
                    />
                  ) : (
                    "IMG"
                  )
                }
                variant={"classic"}
                showIcon={false}
                accept="image/*"
                labelSize={"small"}
                labelColor={"#000"}
                iconColor={"#000"}
                iconSize={"16"}
                // inLineStyles={qdmstyles.zRf}
              ></Upload>
              <div
                id={`${parent_id}_addmember_form_upload_inside_editicon_div`}
                style={{
                  position: "absolute",
                  bottom: "-2px",
                  right: "0px",
                  background: "#DEE5EC",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                  border: "2px solid #FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icons
                  id={`${parent_id}_addmember_form_upload_inside_editicon`}
                  key={"0"}
                  fontIcon="camera"
                  size={12}
                  style={{ color: UIColor.secondaryText.color }}
                ></Icons>
              </div>
            </Div>
          </div>

          <Div
            id={`${parent_id}_addmember_form_firstname_textfield_parent_div`}
            style={{ marginBottom: 16 }}
          >
            <Div
              id={`${parent_id}_addmember_form_firstname_textfield_sublevel_div`}
              key={"3"}
              className={"qdm"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "16px",
                lineHeight: 1,
              }}
            >
              <TextInput
                id={`${parent_id}_addmember_form_firstname_textfield`}
                key={"0"}
                type={"text"}
                name={"text"}
                variant={"outlined"}
                helperTextType={"error"}
                maxLength={20}
                label={
                  <div
                    id={`${parent_id}_addmember_form_firstname_label_div`}
                    className={"pc_regular"}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      marginBottom: 8,
                    }}
                  >
                    First name{" "}
                    <span
                      id={`${parent_id}_addmember_form_firstname_span`}
                      style={{ color: UIColor.error.color }}
                    >
                      *
                    </span>
                  </div>
                }
                autoFocus={false}
                autoComplete={"off"}
                search={false}
                borderColor={UIColor.primaryColor.color}
                elevation={"0"}
                placeholder={"First name"}
                size={"medium"}
                value={this.state.firstName}
                onChange={(e) => {
                  if (e.target.value || e.target.value === "") {
                    this.setState({
                      ...this.state,
                      error: {
                        ...this.state.error,
                        firstName:
                          e.target.value === ""
                            ? false
                            : !checkName(e.target.value),
                      },
                    });
                  }
                  this.changeState("firstName", e.target.value);
                }}
                // style={qdmstyles.vQU}
                error={this.state?.error?.firstName}
                className={classes.textField}
                // helperText=""
              ></TextInput>

              <TextInput
                id={`${parent_id}_addmember_form_middlename_textfield`}
                key={"1"}
                type={"text"}
                name={"text"}
                variant={"outlined"}
                maxLength={20}
                helperTextType={"error"}
                label={
                  <div
                    id={`${parent_id}_addmember_form_middlename_label_textfield`}
                    className={"pc_regular"}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      marginBottom: 8,
                    }}
                  >
                    Middle name
                  </div>
                }
                autoFocus={false}
                autoComplete={"off"}
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                elevation={"0"}
                placeholder={"Middle name"}
                size={"medium"}
                value={this.state.middleName}
                onChange={(e) => {
                  if (e.target.value) {
                    this.setState({
                      ...this.state,
                      error: {
                        ...this.state.error,
                        middleName: !checkName(e.target.value),
                      },
                    });
                  } else if (e.target.value === "") {
                    this.setState({
                      ...this.state,
                      error: {
                        ...this.state.error,
                        middleName: false,
                      },
                    });
                  }
                  this.changeState("middleName", e.target.value);
                }}
                // style={qdmstyles.BAIIv}
                className={classes.textField}
              ></TextInput>

              <TextInput
                id={`${parent_id}_addmember_form_lastname_textfield`}
                key={"2"}
                type={"text"}
                name={"text"}
                maxLength={20}
                variant={"outlined"}
                helperTextType={"error"}
                label={
                  <div
                    id={`${parent_id}_addmember_form_lastname_label_textfield`}
                    className={"pc_regular"}
                    style={{
                      fontSize: "12px",
                      color: UIColor.secondaryText.color,
                      marginBottom: 8,
                    }}
                  >
                    Last name
                    {/* <span style={{ color: UIColor.error.color }}>*</span> */}
                  </div>
                }
                autoFocus={false}
                autoComplete={"off"}
                search={false}
                borderColor={UIColor.lineBorderFill.color}
                elevation={"0"}
                placeholder={"Last name"}
                size={"medium"}
                value={this.state.lastName}
                onChange={(e) => {
                  if (e.target.value) {
                    this.setState({
                      ...this.state,
                      error: {
                        ...this.state.error,
                        lastName: !checkName(e.target.value),
                      },
                    });
                  } else if (e.target.value === "") {
                    this.setState({
                      ...this.state,
                      error: {
                        ...this.state.error,
                        lastName: false,
                      },
                    });
                  }
                  this.changeState("lastName", e.target.value);
                }}
                // style={qdmstyles.pyYgb}
                className={classes.textField}
                error={this.state?.error?.lastName}
              ></TextInput>
            </Div>
            {(this.state.error?.firstName ||
              this.state.error?.middleName ||
              this.state.error?.lastName) && (
              <div
                style={{ marginTop: 8 }}
                id={`${parent_id}_addmember_form_firstname_error_label`}
              >
                <Text
                  id={`${parent_id}_addmember_form_firstname_error_text`}
                  className={"pc_regular"}
                  style={{ color: UIColor.error.color, fontSize: 14 }}
                >
                  {this.getErrorMessage()}
                </Text>
              </div>
            )}
          </Div>

          <TapSelect
            id={`${parent_id}_addmember_form_gender_tapselect`}
            key={"4"}
            fullWidth={false}
            label={
              <H6
                id={`${parent_id}_addmember_form_gender_label_div`}
                className={"pc_regular"}
                style={{
                  fontSize: "12px",
                  color: UIColor.secondaryText.color,
                  lineHeight: 1,
                  margin: 0,
                  marginBottom: 8,
                  letterSpacing: 0,
                  marginBottom: 0,
                }}
              >
                Gender
                <span
                  id={`${parent_id}_addmember_form_gender_span`}
                  style={{ color: UIColor.error.color }}
                >
                  *
                </span>
              </H6>
            }
            // label={false}
            selectedBorderColor={UIColor.secondaryButtonColor.color}
            selectedBackgroundColor={UIColor.secondaryButtonColor.color}
            selectedTextColor="#fff"
            borderColor={UIColor.differentiationBackground.color}
            backgroundColor={UIColor.differentiationBackground.color}
            textSize="16"
            iconHeight="20"
            iconWidth="20"
            textColor={UIColor.secondaryText.color}
            options={[
              { value: "Male", label: "Male" },
              { value: "Female", label: "Female" },
              { value: "Others", label: "Others" },
            ]}
            value={{ value: this.state.gender, label: this.state.gender }}
            onChange={(e) => {
              this.setState({
                ...this.state,
                error: {
                  ...this.state.error,
                  gender: false,
                },
              });
              this.changeState("gender", e.value);
            }}
            style={{ marginTop: "", paddingTop: "" }}
            defaultSelected={{
              value: this.state.gender,
              label: this.state.gender,
            }}
            inLineStyles={{
              //borderColor: UIColor.secondaryButtonColor.color,
              fontSize: "14px",
            }}
            className={classes.tapSelect}
            // style={qdmstyles.SREcn}
          ></TapSelect>
          {this.state.error?.gender && (
            <div
              style={{ marginTop: "8px" }}
              id={`${parent_id}_addmember_form_gender_error_div`}
            >
              <Text
                id={`${parent_id}_addmember_form_gender_error_text`}
                className={"pc_regular"}
                style={{ color: UIColor.error.color, fontSize: 14 }}
              >
                {"Please select the Gender"}
              </Text>
            </div>
          )}
          <DobAge
            parentId="addmember_form_select_dob_textinput"
            dateLabel="DOB"
            isRequired
            dateValue={this.state.dob?.val}
            onChange={(value) => {
              this.setState({
                ...this.state,
                error: {
                  ...this.state.error,
                  dob: false,
                },
              });
              if (value) {
                const milli = moment(value, "YYYY-MM-DD").valueOf();
                const date = {
                  val: moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                  milli,
                };
                this.changeState("dob", date);
              } else {
                this.changeState("dob", {
                  val: null,
                  milli: null,
                  value: "",
                });
              }
            }}
            maxDate={new Date()}
            minDate={moment(new Date()).subtract(200, "years")._d}
            error={this.state.error?.dob && true}
            labelStyle={{ margin: "16px 0px 8px 0px" }}
            placeholder="dd-mm-yyyy"
            top={48}
            right={10}
          />
          {this.state.error?.dob && (
            <div
              style={{ marginTop: "8px" }}
              id={`${parent_id}_addmember_form_dob_error_parent_div`}
            >
              <Text
                id={`${parent_id}_addmember_form_dob_error_text`}
                className={"pc_regular"}
                style={{ color: UIColor.error.color, fontSize: 14 }}
              >
                {"Please enter the valid date dd-mm-yyyy"}
              </Text>
            </div>
          )}
          <H6
            id={`${parent_id}_addmember_form_mobilenumber_text`}
            key={"7"}
            name={
              <span
                id={`${parent_id}_addmember_form_mobilenumber_inside_text`}
                style={{
                  fontSize: "12px",
                }}
                className={"pc_regular"}
              >
                Enter your Mobile number{" "}
                <span
                  id={`${parent_id}_addmember_form_mobilenumber_inside_star`}
                  style={{ color: UIColor.error.color }}
                >
                  *
                </span>
              </span>
            }
            style={{
              fontSize: "12px",
              color: UIColor.secondaryText.color,
              lineHeight: 1,
              margin: 0,
              marginBottom: 8,
              letterSpacing: 0,
              marginTop: "16px",
            }}
          ></H6>
          <div className={classes.mobile} ref={this.mobileRef}>
            <MobileNumberWithCode
              id={`${parent_id}_addmember_form_mobilenumberwithcode`}
              key={"8"}
              type={"number"}
              label={"Mobile Number"}
              borderColor={UIColor.lineBorderFill.color}
              maxLength={30}
              borderRadius={"6"}
              disabled={false}
              size={"medium"}
              style={{ marginBottom: "8px", border: "1px solid #DEE5EC" }}
              value={
                this.state?.mobileNo?.mobileNo
                  ? this.state?.mobileNo?.mobileNo
                  : this.props.signUpData?.mobileNo?.mobileNo
              }
              // defaultValue={this.state.mobileNo.mobileNo}
              dropdown={
                this.state?.dropdownData?.length > 0
                  ? this.state?.dropdownData?.[0]
                  : this.state?.mobileNo?.countryData
              }
              // dropdownData={this.state.dropdownData}
              dropdownData={this?.props?.CountryCode?.data}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  error: {
                    ...this.state.error,
                    mobileNo: false,
                  },
                });
                this.changeState("mobileNo", e);
              }}
              // onChange={(e) => console.log(e,"e")}
              className={
                (this.state.error?.mobileNo || this.state.error?.countryData) &&
                "errorBorder"
              }
              error={
                this.state.error?.mobileNo || this.state.error?.countryData
                  ? true
                  : false
              }
            ></MobileNumberWithCode>
          </div>
          {(this.state.error?.mobileNo || this.state.error?.callingCodes) && (
            <div
              id={`${parent_id}_addmember_form_mobilenumberwithcode_error_parent_div`}
              style={{ marginTop: "8px", marginBottom: 16 }}
            >
              {this.state.error?.callingCodes && (
                <Text
                  id={`${parent_id}_addmember_form_mobilenumberwithcode_error_text`}
                  style={{
                    color: UIColor.error.color,
                    fontSize: 14,
                  }}
                  className={"pc_regular"}
                >
                  {"Please select the country code"}
                </Text>
              )}
              {!this.state.error?.callingCodes &&
                this.state.error?.mobileNo && (
                  <Text
                    id={`${parent_id}_addmember_form_mobilenumberwithcode_empty_error_text`}
                    className={"pc_regular"}
                    style={{ color: UIColor.error.color, fontSize: 14 }}
                  >
                    {"Please enter the mobile number"}
                  </Text>
                )}
            </div>
          )}
          <TermsAndConditions
            id={`${parent_id}_addmember_form_send_appointment_mobilenumber_termsandconditions`}
            value={true}
            checkboxSize={14}
            checkboxTickColor={"#fff"}
            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
            checkboxBorderColor={UIColor.lineBorderFill.color}
            checkboxBackgroundColor={UIColor.differentiationBackground.color}
            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
            disabled
            lable={
              <H6
                id={`${parent_id}_addmember_form_send_appointment_mobilenumber_span`}
                style={{
                  fontSize: "12px",
                  color: UIColor.primaryText.color,
                  margin: 0,
                  letterSpacing: 0,
                }}
                className={"pc_regular"}
              >
                Send appointment update and notification to this Mobile number
              </H6>
            }
            inLineStyles={{ lineHeight: 1 }}
            //className={classes.termsAndConditions}
          />
          <div
            id={`${parent_id}_addmember_form_mailid_parent_div`}
            style={{ marginTop: 16 }}
          >
            <H6
              id={`${parent_id}_addmember_form_mailid_text`}
              key={"13"}
              // name={'Mail Id (optional)'}
              name={
                <span
                  id={`${parent_id}_addmember_form_mailid_inside_label_span`}
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                  }}
                  className={"pc_regular"}
                >
                  Mail Id
                  {this.state.emailIdChecked ? (
                    <span style={{ color: UIColor.error.color }}> *</span>
                  ) : null}
                </span>
              }
              style={{
                fontSize: "12px",
                color: UIColor.secondaryText.color,
                lineHeight: 1,
                margin: 0,
                marginBottom: 8,
                letterSpacing: 0,
              }}
            ></H6>
          </div>
          <TextInput
            id={`${parent_id}_addmember_form_email_textfield`}
            key={"9"}
            type={"text"}
            name={""}
            variant={"outlined"}
            helperTextType={"error"}
            label={""}
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            // borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"example@gmail.com"}
            // size={"medium"}
            style={
              this.state?.error?.emailId ? Styles.errorStyle : Styles.inputStyle
            }
            value={this.state.emailId}
            onChange={(e) => this.changeState("emailId", e.target.value)}
            error={this.state?.error?.emailId}
          ></TextInput>
          {this.state.emailId && this.state.error?.emailId && (
            <div
              id={`${parent_id}_addmember_form_error_parent_div`}
              style={{ marginTop: 8 }}
            >
              <Text
                id={`${parent_id}_addmember_form_error_text`}
                style={{ color: UIColor.error.color, fontSize: 14 }}
                className="pc_regular"
              >
                {"Please Enter valid Email Id"}
              </Text>
            </div>
          )}
          <TermsAndConditions
            id={`${parent_id}_addmember_form_send_appointment_emailid_termsandcondition`}
            checkboxSize={14}
            checkboxTickColor={"#fff"}
            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
            checkboxBorderColor={UIColor.lineBorderFill.color}
            checkboxBackgroundColor={UIColor.differentiationBackground.color}
            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
            value={this.state.emailIdChecked}
            onChange={(e) => {
              this.changeState("emailIdChecked", e);
            }}
            className={classes.termsAndConditions}
            lable={
              <H6
                id={`${parent_id}_addmember_form_send_appointment_emailid_termsandcondition_inside_span`}
                className="pc_regular"
                style={{
                  fontSize: "12px",
                  color: UIColor.primaryText.color,
                  margin: 0,
                  letterSpacing: 0,
                }}
              >
                Send appointment update and notification to this mail id
              </H6>
            }
            inLineStyles={{ lineHeight: 1, marginTop: 16 }}
          />
          {addNewMember && (
            <>
              <H6
                id={`${parent_id}_addmember_form_relationship_text`}
                key={"10"}
                name={
                  <span
                    id={`${parent_id}_addmember_form_relationship_inside_span`}
                    className="pc_regular"
                  >
                    What's your relationship with{" "}
                    {this.state.firstName || "the person"}?{" "}
                    <span
                      id={`${parent_id}_addmember_form_relationship_inside_star`}
                      style={{ color: UIColor.error.color }}
                    >
                      *
                    </span>
                  </span>
                }
                style={{
                  color: UIColor.secondaryText.color,
                  margin: 0,
                  marginTop: "16px",
                  fontSize: "12px",
                  lineHeight: 1,
                }}
                className={"pc_regular"}
              ></H6>
              <TapSelect
                id={`${parent_id}_addmember_form_relationship_tapselect`}
                key={"11"}
                label={false}
                fullWidth={false}
                selectedBorderColor={UIColor.secondaryButtonColor.color}
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                selectedTextColor="#fff"
                borderColor={UIColor.differentiationBackground.color}
                backgroundColor={UIColor.differentiationBackground.color}
                textColor={UIColor.secondaryText.color}
                textSize="16"
                iconHeight="20"
                iconWidth="20"
                title={false}
                name={false}
                inLineStyles={{
                  fontSize: "14px",
                  fontFamily: "'pc_regular' !important",
                }}
                options={relationshipData?.data ?? []}
                // value={this.state.relation}
                defaultSelected={{
                  value: this.state.relation?.value,
                  label: this.state.relation?.label,
                }}
                value={{
                  value: this.state.relation?.value,
                  label: this.state.relation?.label,
                }}
                onChange={(e) => this.changeState("relation", e)}
                iconPosition={"left"}
                className={classes.tapSelect}
              ></TapSelect>{" "}
            </>
          )}

          <Button
            id={`${parent_id}_addmember_form_relationship_sendotp_button`}
            key={"12"}
            type={"button"}
            disabled={this.props?.registerUsers?.loading}
            variant={"outlined"}
            name={
              this.props?.registerUsers?.loading
                ? "Sending OTP..."
                : addNewMember
                ? this.props?.addMember?.loading ||
                  this.props?.readPerson?.loading
                  ? "Adding..."
                  : "Send Otp & Add Member"
                : this.state.loadingReadPerson
                ? "Signing Up..."
                : this.props?.readPerson?.loading ||
                  this.props?.registerUsers?.loading
                ? "Sending OTP..."
                : "Send OTP & Sign Up"
            }
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              marginTop: "40px",
              borderRadius: "8px",
              padding: "8px 16px",
              marginBottom: "10px",
              backgroundColor: UIColor.primaryColor.color,
              border: "none",
              fontSize: "14px",
              height: "40px",
            }}
            onClick={this.handleSignUp}
            className={"pc_medium"}
          ></Button>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  registerUsers: state?.authSlice?.registerUsers,
  addMember: state?.personApiSlice?.upsertPerson,
  readPerson: state?.personApiSlice?.readPerson,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  guestUserInfo: state?.authSlice?.guestInfo,
  CountryCode: state?.authSlice?.country_code_list,
  relationshipData: state?.chiefCompilenceMastersSlice?.relationship_masters,
});

AppointmentDrawerSigningPage.contextType = AlertContext;

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(AppointmentDrawerSigningPage))
);
