import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import TextField from "@material-ui/core/TextField";

import OutlinedInput from "@material-ui/core/OutlinedInput";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "8px 0px 0px 0px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  lable: {
    fontSize: 13,
    color: "#6F6F6F",
    marginTop: 14,
    marginBottom: 10,
    fontWeight: 400,
  },
  tabSecBtn: {
    padding: "6px 16px",
    textTransform: "none",
    marginRight: "8px",
    marginTop: 8,
    fontSize: 12,
    fontWeight: "400",
  },
  Iagree: {
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  unitDiv: {
    "& .MuiTypography-colorTextSecondary": {
      fontSize: 14,
    },
  },
}));

export const FormVitels = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container style={{ marginBottom: 8 }} spacing={2}>
        {props.data[0].range.map((val, i) => (
          <Grid item xs={3}>
            <FormControl>
              <div style={{ marginBottom: 8 }}>
                <FormLabel className={classes.lable}>{val.key}</FormLabel>
              </div>
              <OutlinedInput
                id="outlined-basic"
                variant="outlined"
                style={{ width: "100%" }}
                endAdornment={
                  <InputAdornment position="end" className={classes.unitDiv}>
                    {val.unit}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
      {props?.data[0]?.options[0]?.bodySite ? (
        <div style={{ marginBottom: 8 }}>
          <FormLabel className={classes.lable}>Recorded body site</FormLabel>
          <Grid>
            {props?.data[0]?.options[0]?.bodySite?.map((val, i) => {
              return (
                <ToggleButtonGroup>
                  <ToggleButton className={classes.tabSecBtn}>
                    {val.title}
                  </ToggleButton>
                </ToggleButtonGroup>
              );
            })}
          </Grid>
        </div>
      ) : null}
      {props?.data[0]?.options[0]?.postion ? (
        <div style={{ marginBottom: 8 }}>
          <FormLabel className={classes.lable}>Recorded body site</FormLabel>
          <Grid>
            {props?.data[0]?.options[0]?.postion?.map((val, i) => {
              return (
                <ToggleButtonGroup>
                  <ToggleButton className={classes.tabSecBtn}>
                    {val.title}
                  </ToggleButton>
                </ToggleButtonGroup>
              );
            })}
          </Grid>
        </div>
      ) : null}
      {props?.data[0]?.options[0]?.testTaken ? (
        <div style={{ marginBottom: 8 }}>
          <FormLabel className={classes.lable}>Test Taken</FormLabel>
          <Grid>
            {props?.data[0]?.options[0]?.testTaken?.map((val, i) => {
              return (
                <ToggleButtonGroup>
                  <ToggleButton className={classes.tabSecBtn}>
                    {val.title}
                  </ToggleButton>
                </ToggleButtonGroup>
              );
            })}
          </Grid>
        </div>
      ) : null}
      <div style={{ marginBottom: 8 }}>
        <FormControlLabel
          className={classes.Iagree}
          control={
            <Checkbox
              // checked={state.checkedB}
              // onChange={handleChange}
              name="If unable to measure, Please select the checkbox"
              color="primary"
            />
          }
          label="If unable to measure, Please select the checkbox"
        />
      </div>
    </div>
  );
};
